<template>
  <page-content>

    <common-table
        ref="table"
        search-placeholder="搜索MAC/姓名/电话"
        path="web/iot/fault"
        :queryParams="queryParams"
        :columns="columns">

      <template slot="search">
        <!--                <a-button @click="$refs.table.export('报警记录')">导出Excel</a-button>-->
      </template>

      <template slot="autoReport" slot-scope="{record}">
        <a-tag v-if="record.autoReport" color="gray">设备上报</a-tag>
        <a-tag v-else color="cyan">手动上报</a-tag>
      </template>

    </common-table>


  </page-content>
</template>
<script>
export default {

  data() {
    return {
      getListInterval: null,
      queryParams: {
        // deviceId:this.$route.params.deviceId
      },
      vendorList:[]
    }
  },

  created() {
    this.getListInterval = setInterval(() => {
      this.getList()
    }, 30000)
    this.getVendorList()
  },
  destroyed() {
    clearInterval(this.getListInterval)
  },

  computed: {
    columns() {
      let vendorFilter = [{text: '全部', value: ''}]
      this.vendorList.forEach(item=>{
        vendorFilter.push({text: item.name, value: item.id})
      })
      return [
        {
          title: '经销商',
          dataIndex: 'vendorId',
          filters: vendorFilter,
          filterMultiple: false,
          customRender(text,record){
            return record.vendorName
          }
        },
        {
          title: '设备名称',
          dataIndex: 'deviceName',
        },
        {
          title: '设备MAC',
          dataIndex: 'deviceKey',
        },
        {
          title: '姓名',
          dataIndex: 'name',
        },
        {
          title: '联系电话',
          dataIndex: 'contact',
        },
        {
          title: '安装地址',
          dataIndex: 'address',
        },
        {
          title: '故障描述',
          dataIndex: 'content',
        },
        {
          title: '故障类型',
          dataIndex: 'autoReport',
          scopedSlots: {customRender: 'autoReport'},
          filters: [
            {text: '全部', value: ""},
            {text: '设备上报', value: true},
            {text: '手动上报', value: false}
          ],
          filterMultiple: false,
        },
        {
          title: '时间',
          dataIndex: 'createdAt',
          sorter: true
        },


      ]
    }
  },
  methods: {
    getList() {
      this.$refs.table.getData()
    },
    getVendorList(){
      this.$get('vendor?pageSize=100').then((r) => {
        let data = r.data
        this.vendorList = data.rows
      })
    }

  }
}
</script>
<style lang="less" scoped>

</style>
